import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import {Col, Row, Tabs, Spin} from 'antd';
import 'antd/dist/antd.css';
import AprCard from "../component/AprCard";
import DateRangeDrawer from "../component/DateRangeDrawer";
import OrgListDrawer from "../component/OrgListDrawer";
import AprHead from "../component/AprHead";

const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');

const ApprovalStat = () => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 选择日期抽屉 状态
    const [timeOpen, setTimeOpen] = useState(false);
    // 选择事业部抽屉 状态
    const [orgOpen, setOrgOpen] = useState(false);
    // 时间范围
    const [chooseTime, setChooseTime] = useState(sysConst.DATE_RANGE[3]);
    const [dateRange, setDateRange] = useState({start: "", end: ""});
    // 事业部范围
    const [chooseOrg, setChooseOrg] = useState(sysConst.ORG_RANGE[0]);
    // 采购申请单 相关统计数据（hours：单据平均耗时，billCnt：单据量，userCnt：审批人数，eChartOpt：图表参数）
    const [purchaseReqData, setPurchaseReqData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});
    // 采购订单
    const [purchaseOrdData, setPurchaseOrdData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});
    // 收料通知单
    const [recMateInfoData, setRecMateInfoData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});
    // 采购入库单
    const [purCInStoreData, setPurCInStoreData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});
    // 付款申请单
    const [paymentReqData, setPaymentReqData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});
    // 付款单
    const [paymentData, setPaymentData] = useState({hours: 0, billCnt: 0, userCnt: 0, eChartOpt: {}});

    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('采购审批统计');
        // 取得钉钉登录信息
        appAction.loginDingTalk(
            () => {
                getKdData(chooseOrg.num, chooseTime.label);
            }
        );
    }, []);

    const changeTab = (key) => {};

    // eCharts 图表 参数设置
    const getEChartsOpt = (resData) => {
        // 图表数据集合
        let eChartData = {xAxisData: [], series1: [], series2: []};
        // 循环金蝶数据源，组装eCharts格式数据,暂定显示前5条
        resData.data.forEach((item, index) => {
            if (index < 5) {
                eChartData.xAxisData.push(item.USER_NAME);
                eChartData.series1.push(item.BILL_COUNT);
                eChartData.series2.push(item.AVERAGE_HOURS);
            }
        });

        return {
            hours: resData.average_hours,
            billCnt: resData.bill_count,
            userCnt: resData.rowcount,
            eChartOpt: {
                // 标题
                title: {text: '人员耗时排行'},
                // 提示文字
                tooltip: {},
                legend: {
                    x: 'right',
                    y: 'top',
                    padding: [6, 18, 0, 0],
                    data: ['单据数', '平均时长']
                },
                xAxis: {data: eChartData.xAxisData},
                yAxis: {},
                series: [{name: '单据数', type: 'bar', label: {show: true}, data: eChartData.series1}
                    , {name: '平均时长', type: 'bar', label: {show: true}, data: eChartData.series2}]
            }
        };
    };

    // 调用后端WebApi，取得金蝶 采购流程统计 相关单据统计数据
    const getApprovalStat = (orgNum, rangeName) => {
        // 根据页面选定日期范围（本周，本月等）：取得开始日期，结束日期
        let dateRangeParam = commonUtil.getDateRange(rangeName);
        setDateRange(dateRangeParam);
        // 加载中动画
        setLoading(true);
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/kd/purchase/process/statistics", {
            // 默认：查询大连组织下单据审批统计详情
            orgNum: orgNum, startDay: dateRangeParam.start, endDay: dateRangeParam.end
        })
            .then(res => {
                if (res.data.success) {
                    // console.log(res.data.采购申请单);
                    // 组装不同单据的统计数据，图表数据
                    setPurchaseReqData(getEChartsOpt(res.data['采购申请单']));
                    setPurchaseOrdData(getEChartsOpt(res.data['采购订单']));
                    setRecMateInfoData(getEChartsOpt(res.data['收料通知单']));
                    setPurCInStoreData(getEChartsOpt(res.data['采购入库单']));
                    setPaymentReqData(getEChartsOpt(res.data['付款申请单']));
                    setPaymentData(getEChartsOpt(res.data['付款单']));
                } else {
                    if (!res.data.success) {
                        // res.data.message=会话信息已丢失，请重新登录
                        // appAction.loginKd();
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getApprovalStat no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getApprovalStat error');
            console.log("getApprovalStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const changeTime = (rangeItem) => {
        // 关闭 抽屉
        setTimeOpen(false);
        // 更新 选中值
        setChooseTime(rangeItem);
        // 取得 钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 调用金蝶接口 取得数据
                getKdData(chooseOrg.num, rangeItem.label)
            }
        );
    };

    const changeOrg = (orgItem) => {
        // 关闭 抽屉
        setOrgOpen(false);
        // 更新 选中值
        setChooseOrg(orgItem);
        // 取得 钉钉登录信息
        appAction.loginDingTalk(
            () => {
                // 调用金蝶接口 取得数据
                getKdData(orgItem.num, chooseTime.label);
            }
        );
    };

    // 取得金蝶审批数据
    const getKdData = async (orgNum, rangeName) => {
        // // 金蝶未登录时，先执行金蝶登录
        // if (!localUtil.getSessionItem(sysConst.KD_LOGIN_STATUS)) {
        //     await appAction.loginKd();
        // }
        // 调用金蝶接口 取得数据
        getApprovalStat(orgNum, rangeName);
    };

    // 刷新
    const refresh = () => {
        getKdData(chooseOrg.num, chooseTime.label)
    };

    return (
        <>
            {/* 加载中动画，居中显示 */}
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            {/* 审批统计 固定头部内容，【时间范围：本周，本月，本年等】【事业部：大连兆和总部等等...】 */}
            <AprHead setTimeOpen={setTimeOpen} chooseTime={chooseTime} dateRange={dateRange} refresh={refresh}
                     setOrgOpen={setOrgOpen} chooseOrg={chooseOrg} showIcon={true}/>

            {/* 选择日期 抽屉 */}
            <DateRangeDrawer timeOpen={timeOpen} setOpen={setTimeOpen} changeTime={changeTime}/>

            {/* 选择事业部 抽屉 */}
            <OrgListDrawer orgOpen={orgOpen} setOpen={setOrgOpen} changeOrg={changeOrg}/>

            {/* 上部 总体统计 antd的 card组件 */}
            <Row>
                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"采购申请单"} dat={purchaseReqData} tab={1} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>

                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"采购订单"} dat={purchaseOrdData} tab={2} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>

                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"收料通知单"} dat={recMateInfoData} tab={3} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>

                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"采购入库单"} dat={purCInStoreData} tab={4} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>

                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"付款申请单"} dat={paymentReqData} tab={5} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>

                <Col span={12} style={{paddingLeft:5,paddingRight:5,marginTop: 5}}>
                    <AprCard msg={"付款单"} dat={paymentData} tab={6} date={chooseTime.value} org={chooseOrg.value}/>
                </Col>
            </Row>

            {/* 图表 */}
            <Tabs
                defaultActiveKey="1"
                onChange={changeTab}
                tabBarStyle={{paddingLeft:5}}
                items={[
                    {
                        key: '1',
                        label: `采购申请单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={purchaseReqData.eChartOpt}/>)
                    }
                    , {
                        key: '2',
                        label: `采购订单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={purchaseOrdData.eChartOpt}/>)
                    }
                    , {
                        key: '3',
                        label: `收料通知单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={recMateInfoData.eChartOpt}/>)
                    }
                    , {
                        key: '4',
                        label: `采购入库单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={purCInStoreData.eChartOpt}/>)
                    }
                    , {
                        key: '5',
                        label: `付款申请单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={paymentReqData.eChartOpt}/>)
                    }
                    , {
                        key: '6',
                        label: `付款单`,
                        children: (<ReactECharts style={{marginTop: 5}} option={paymentData.eChartOpt}/>)
                    }
                ]}
            />
        </>
    );
};

export default ApprovalStat;