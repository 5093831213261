import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import {Tabs, Spin, DatePicker} from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';

const sysConst = require('../utils/SysConst');
const commonUtil = require('../utils/CommonUtil');
const dingUtil = require('../utils/DingUtils');
const appAction = require('../utils/AppAction');
const localUtil = require('../utils/LocalUtils');

const CrmStat = () => {
    // 页面调用接口时，是否显示加载中动画
    const [loading, setLoading] = useState(false);
    // 选择年
    const [chooseYear, setChooseYear] = useState(dayjs().add(-1,'year'));
    // 集团整体（只统计法人数据）
    const [companyInfo, setCompanyInfo] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 兆和空气 01（统计法人下事业部信息）
    const [comp01Info, setComp01Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 苏州智能 05（统计法人下事业部信息）
    const [comp05Info, setComp05Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 天津兆和 04（统计法人下事业部信息）
    const [comp04Info, setComp04Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 苏州环能 06（统计法人下事业部信息）
    const [comp06Info, setComp06Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 上海兆和 07（统计法人下事业部信息）
    const [comp07Info, setComp07Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 兆和数字 08（统计法人下事业部信息）
    const [comp08Info, setComp08Info] = useState({name:[],saleTarget:{},saleInfo:{},nicheInfo:{}});
    // 权限标记： -1默认值，1：有权限（包含集团经营驾驶舱权限），0：无权限（钉钉中没有经营驾驶舱权限）
    const [roleFlag, setRoleFlag] = useState(-1);
    // 第一次渲染时调用
    useEffect(() => {
        // 使用手机钉钉打开时, 设置标题内容
        dingUtil.setDingTitle('经营数据统计');
        // 取得钉钉登录信息
        setLoading(true);
        appAction.loginDingTalk(
            () => {
                let roles = localUtil.getSessionItem(sysConst.DING_USER_ROLES);
                // 有权限时，再调用API接口 取得销售统计数据
                if (roles != null && roles !== '' && roles.indexOf('集团经营驾驶舱:集团经营驾驶舱') > -1) {
                    getSaleInfoStat('2023');
                    setRoleFlag(1);
                } else {
                    setRoleFlag(0);
                }
            }
        );
        setLoading(false);
    }, []);

    // 图表1：外部订单目标额
    const getSaleTargetOpt = (subtext, data) => {
        return {
            title: {text: '外部订单目标额', subtext: subtext + '亿', x: 'center', subtextStyle: {fontSize: 14,fontWeight: 'bold'}},
            tooltip: {trigger: 'item', formatter: '{b}: {c} 亿 ({d}%)'},
            legend: {orient: 'vertical', left: 'left'},
            series: [{name: '', type: 'pie', radius: '50%', data: data}]
        };
    };

    // 图表2：销售信息
    // color：调色盘颜色列表。如果系列没有设置颜色，则会依次循环从该列表中取颜色作为系列颜色。 默认为：
    // color: ['red', 'green','yellow','blue','purple'],
    // ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
    const getSaleInfoOpt = (optData) => {
        // 单位数值 转为亿，并保留2位小数
        let seriesSale = [];
        let seriesWin = [];
        let seriesLeft = [];
        // 集团00 时，去掉 最后一个组织 兆和数字的值
        if (optData.orgNum === '00') {
            seriesSale = optData.seriesSale.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesWin = optData.seriesWin.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesSale.splice(seriesSale.length-1);
            seriesWin.splice(seriesWin.length-1);
            seriesLeft.splice(seriesLeft.length-1);
            optData.yAxisData.splice(5);
        } else if (optData.orgNum === '08') {
            // 兆和数字 08时，计数单位 按照万
            seriesSale = optData.seriesSale.map((item)=>{return (item / 10000).toFixed(2)});
            seriesWin = optData.seriesWin.map((item)=>{return (item / 10000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 10000).toFixed(2)});
        } else {
            seriesSale = optData.seriesSale.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesWin = optData.seriesWin.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
        }

        return {
            title: {text: '销售信息',subtext: optData.subtext,x: 'center', subtextStyle: {fontSize: 13,fontWeight: 'bold'}},
            tooltip: {
                trigger: 'axis',
                extraCssText: 'height:auto;',
                formatter: function (params) {
                    //params[0].name表示x轴数据（公司名称），params[0].value表示销售数值
                    let percent = 0;
                    percent = params[2].value == 0 ? 100 : commonUtil.percentage(parseFloat(params[0].value) + parseFloat(params[1].value), parseFloat(params[0].value) + parseFloat(params[1].value) + parseFloat(params[2].value));
                    let str = params[0].name + '（完成率 ' + percent + '%）' + '<br/>';
                    // params是数组格式
                    // params.forEach((item, index) => {
                    //     str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                    //         + item.color + ";'></span>" + "\t" + item.seriesName + " : " + (optData.seriesSale[index]).toFixed(2) + '<br/>'
                    // });
                    for (let item of params) {
                        str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                            + item.color + ";'></span>" + "\t" + item.seriesName + " : " + item.value + '<br/>'
                    }
                    return str
                },
            },
            color: ['#5470c6','#fac858','#91cc75'],
            legend: {orient: 'vertical',left: 'left'},
            grid: {top: '25%',left: '3%',right: '4%',bottom: '3%',containLabel: true},
            xAxis: {type: 'value'},
            yAxis: {type: 'category',data: optData.yAxisData},
            series: [
                {name: '销售额', type: 'bar', stack: 'total', label: {show: true}, data: seriesSale},
                {name: '中标未签', type: 'bar', stack: 'total', label: {show: true}, data: seriesWin},
                {name: '未完成额', type: 'bar', stack: 'total', label: {show: true}, data: seriesLeft}
            ]
        }
    };

    // 图表3：商机
    const getNicheInfoOpt = (optData) => {
        // 单位数值 转为亿，并保留2位小数
        let seriesNiche = [];
        let seriesKeyNiche = [];
        let seriesLeft = [];

        // 集团00 时，去掉 最后一个组织 兆和数字的值
        if (optData.orgNum === '00') {
            seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesNiche.splice(seriesNiche.length-1);
            seriesKeyNiche.splice(seriesKeyNiche.length-1);
            seriesLeft.splice(seriesLeft.length-1);
            optData.yAxisData.splice(5);
        } else if (optData.orgNum === '08') {
            // 兆和数字 08时，计数单位 按照万
            seriesNiche = optData.seriesNiche.map((item)=>{return (item / 10000).toFixed(2)});
            seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 10000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 10000).toFixed(2)});
        } else {
            seriesNiche = optData.seriesNiche.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesKeyNiche = optData.seriesKeyNiche.map((item)=>{return (item / 100000000).toFixed(2)});
            seriesLeft = optData.seriesLeft.map((item)=>{return (item / 100000000).toFixed(2)});
        }

        return {
            title: {text: '商机信息',subtext: optData.subtext,x: 'center',subtextStyle: {fontSize: 13,fontWeight: 'bold',color: optData.subtextColor}},
            tooltip: {
                trigger: 'axis',
                extraCssText: 'height:auto;',
                formatter: function (params) {
                    //params[0].name表示x轴数据（公司名称），params[0].value表示销售数值
                    let multiples = params[2].value == 0 ? '0.00' : (params[0].value / params[2].value).toFixed(2);
                    let str = params[0].name
                        + '（商机倍数 <span style="color:' + (multiples > 3 ? 'green' : (multiples < 1 ? 'red' : 'orange')) + '">' + multiples
                        + '</span>）' + '<br/>';
                    //params是数组格式
                    for (let item of params) {
                        str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:"
                            + item.color + ";'></span>" + "\t" + item.seriesName + " : " + item.value + '<br/>'
                    }
                    return str
                },
            },
            color: ['#5470c6','#ee6666','#91cc75'],
            legend: {orient: 'vertical',left: 'left'},
            grid: {top: '25%',left: '3%',right: '4%',bottom: '3%',containLabel: true},
            xAxis: {type: 'value'},
            yAxis: {type: 'category',data: optData.yAxisData},
            series: [
                {name: '商机金额',type: 'bar',label: {show: true},data: seriesNiche},
                {name: '重点商机',type: 'bar',label: {show: true},data: seriesKeyNiche},
                {name: '未完成额',type: 'bar',label: {show: true},data: seriesLeft}
            ]
        }
    };

    // 调用后端WebApi，取得金蝶销售订单以及CRM商机 统计数据
    const getSaleInfoStat = (year) => {
        // 加载中动画
        setLoading(true);
        // 本年的日期范围
        // let dateRangeParam = commonUtil.getDateRange("本年");
        // 根据条件 调用后端接口
        axios.post(sysConst.DOMAIN + "/crm/niche/query", {
            year: year
        })
            .then(res => {
                // 集团法人数组
                let companyNms = sysConst.SALE_TARGET_2023.company.map((item)=>{return item.name});
                // 兆和空气法人下 事业部
                let company01Nms = sysConst.SALE_TARGET_2023.company01.map((item)=>{return item.name});
                // 天津兆和法人下 事业部
                let company04Nms = sysConst.SALE_TARGET_2023.company04.map((item)=>{return item.name});
                // 苏州智能法人下 事业部
                let company05Nms = sysConst.SALE_TARGET_2023.company05.map((item)=>{return item.name});
                // 苏州环能法人下 事业部
                let company06Nms = sysConst.SALE_TARGET_2023.company06.map((item)=>{return item.name});
                // 上海兆和法人下 事业部
                let company07Nms = sysConst.SALE_TARGET_2023.company07.map((item)=>{return item.name});
                // 兆和数字法人下 事业部
                let company08Nms = sysConst.SALE_TARGET_2023.company08.map((item)=>{return item.name});

                // 返回结果中的 success 为 true
                if (res.data.success) {
                    // 固定值：外部订单目标额 : 常量 暂时是2023年数据，2024的时候 需要重新更新常量内容 TODO
                    let saleTarget = {};
                    // 当前年 若是2023年的话，将2023年订单目标赋值
                    if (year === '2023') {
                        saleTarget = sysConst.SALE_TARGET_2023;
                    }

                    // 集团数据：销售额，未完成额，商机金额
                    let saleAmt = [0,0,0,0,0,0];
                    let winAmt = [0,0,0,0,0,0];
                    let leftAmt = [0,0,0,0,0,0];
                    let nicheAmt = [0,0,0,0,0,0];
                    let keyNicheAmt = [0,0,0,0,0,0];
                    // 兆和空气：销售额，未完成额，商机金额
                    let saleAmt01 = [0,0];
                    let winAmt01 = [0,0];
                    let leftAmt01 = [0,0];
                    let nicheAmt01 = [0,0];
                    let keyNicheAmt01 = [0,0];
                    // 天津兆和
                    let saleAmt04 = [0];
                    let winAmt04 = [0];
                    let leftAmt04 = [0];
                    let nicheAmt04 = [0];
                    let keyNicheAmt04 = [0];
                    // 苏州智能
                    let saleAmt05 = [0,0];
                    let winAmt05 = [0,0];
                    let leftAmt05 = [0,0];
                    let nicheAmt05 = [0,0];
                    let keyNicheAmt05 = [0,0];
                    // 苏州环能
                    let saleAmt06 = [0,0,0,0];
                    let winAmt06 = [0,0,0,0];
                    let leftAmt06 = [0,0,0,0];
                    let nicheAmt06 = [0,0,0,0];
                    let keyNicheAmt06 = [0,0,0,0];
                    // 上海兆和
                    let saleAmt07 = [0];
                    let winAmt07 = [0];
                    let leftAmt07 = [0];
                    let nicheAmt07 = [0];
                    let keyNicheAmt07 = [0];
                    // 兆和数字
                    let saleAmt08 = [0];
                    let winAmt08 = [0];
                    let leftAmt08 = [0];
                    let nicheAmt08 = [0];
                    let keyNicheAmt08 = [0];

                    // 遍历 外部销售订单
                    res.data.out.forEach((currentValue, index) => {
                        switch (currentValue.ORG_NUM) {
                            case "0102":
                                saleAmt[0] = saleAmt[0] + parseFloat(currentValue.AMT);
                                saleAmt01[0] = parseFloat(currentValue.AMT);
                                break;
                            case "0103":
                                saleAmt[0] = saleAmt[0] + parseFloat(currentValue.AMT);
                                saleAmt01[1] = parseFloat(currentValue.AMT);
                                break;
                            case "04":
                                saleAmt[1] = parseFloat(currentValue.AMT);
                                saleAmt04[0] = parseFloat(currentValue.AMT);
                                break;
                            case "0105":
                                saleAmt[2] = saleAmt[2] + parseFloat(currentValue.AMT);
                                saleAmt05[0] = parseFloat(saleAmt05[0]) + parseFloat(currentValue.AMT);
                                break;
                            case "0502":
                                saleAmt[2] = saleAmt[2] + parseFloat(currentValue.AMT);
                                saleAmt05[0] = parseFloat(saleAmt05[0]) + parseFloat(currentValue.AMT);
                                break;
                            case "0503":
                                saleAmt[2] = saleAmt[2] + parseFloat(currentValue.AMT);
                                saleAmt05[1] = parseFloat(currentValue.AMT);
                                break;
                            case "0602":
                                saleAmt[3] = saleAmt[3] + parseFloat(currentValue.AMT);
                                saleAmt06[0] = parseFloat(currentValue.AMT);
                                break;
                            case "0603":
                                saleAmt[3] = saleAmt[3] + parseFloat(currentValue.AMT);
                                saleAmt06[1] = parseFloat(currentValue.AMT);
                                break;
                            case "0604":
                                saleAmt[3] = saleAmt[3] + parseFloat(currentValue.AMT);
                                saleAmt06[2] = parseFloat(currentValue.AMT);
                                break;
                            case "0605":
                                saleAmt[3] = saleAmt[3] + parseFloat(currentValue.AMT);
                                saleAmt06[3] = parseFloat(currentValue.AMT);
                                break;
                            case "07":
                                saleAmt[4] = parseFloat(currentValue.AMT);
                                saleAmt07[0] = parseFloat(currentValue.AMT);
                                break;
                            case "08":
                                saleAmt[5] = parseFloat(currentValue.AMT);
                                saleAmt08[0] = parseFloat(currentValue.AMT);
                                break;
                            default :
                                break;
                        }
                    });

                    // 遍历 已中标未签单
                    res.data.win.forEach((currentValue, index) => {
                        switch (currentValue.orgNo) {
                            case "0102":
                                winAmt[0] = winAmt[0] + parseFloat(currentValue.amt);
                                winAmt01[0] = parseFloat(currentValue.amt);
                                break;
                            case "0103":
                                winAmt[0] = winAmt[0] + parseFloat(currentValue.amt);
                                winAmt01[1] = parseFloat(currentValue.amt);
                                break;
                            case "04":
                                winAmt[1] = parseFloat(currentValue.amt);
                                winAmt04[0] = parseFloat(currentValue.amt);
                                break;
                            case "0105":
                                winAmt[2] = winAmt[2] + parseFloat(currentValue.amt);
                                winAmt05[0] = winAmt05[0] + parseFloat(currentValue.amt);
                                break;
                            case "0502":
                                winAmt[2] = winAmt[2] + parseFloat(currentValue.amt);
                                winAmt05[0] = winAmt05[0] + parseFloat(currentValue.amt);
                                break;
                            case "0503":
                                winAmt[2] = winAmt[2] + parseFloat(currentValue.amt);
                                winAmt05[1] = parseFloat(currentValue.amt);
                                break;
                            case "0602":
                                winAmt[3] = winAmt[3] + parseFloat(currentValue.amt);
                                winAmt06[0] = parseFloat(currentValue.amt);
                                break;
                            case "0603":
                                winAmt[3] = winAmt[3] + parseFloat(currentValue.amt);
                                winAmt06[1] = parseFloat(currentValue.amt);
                                break;
                            case "0604":
                                winAmt[3] = winAmt[3] + parseFloat(currentValue.amt);
                                winAmt06[2] = parseFloat(currentValue.amt);
                                break;
                            case "0605":
                                winAmt[3] = winAmt[3] + parseFloat(currentValue.amt);
                                winAmt06[3] = parseFloat(currentValue.amt);
                                break;
                            case "07":
                                winAmt[4] = parseFloat(currentValue.amt);
                                winAmt07[0] = parseFloat(currentValue.amt);
                                break;
                            case "08":
                                winAmt[5] = parseFloat(currentValue.amt);
                                winAmt08[0] = parseFloat(currentValue.amt);
                                break;
                            default :
                                break;
                        }
                    });

                    // 计算 未完成额
                    leftAmt01[0] = sysConst.SALE_TARGET_2023.company01[0].value * 100000000 - saleAmt01[0] - winAmt01[0];
                    leftAmt01[0] = leftAmt01[0] < 0 ? 0 : leftAmt01[0].toFixed(2);
                    leftAmt01[1] = sysConst.SALE_TARGET_2023.company01[1].value * 100000000 - saleAmt01[1] - winAmt01[1];
                    leftAmt01[1] = leftAmt01[1] < 0 ? 0 : leftAmt01[1].toFixed(2);
                    leftAmt04[0] = sysConst.SALE_TARGET_2023.company04[0].value * 100000000 - saleAmt04[0] - winAmt04[0];
                    leftAmt04[0] = leftAmt04[0] < 0 ? 0 : leftAmt04[0].toFixed(2);
                    leftAmt05[0] = sysConst.SALE_TARGET_2023.company05[0].value * 100000000 - saleAmt05[0] - winAmt05[0];
                    leftAmt05[0] = leftAmt05[0] < 0 ? 0 : leftAmt05[0].toFixed(2);
                    leftAmt05[1] = sysConst.SALE_TARGET_2023.company05[1].value * 100000000 - saleAmt05[1] - winAmt05[1];
                    leftAmt05[1] = leftAmt05[1] < 0 ? 0 : leftAmt05[1].toFixed(2);
                    leftAmt06[0] = sysConst.SALE_TARGET_2023.company06[0].value * 100000000 - saleAmt06[0] - winAmt06[0];
                    leftAmt06[0] = leftAmt06[0] < 0 ? 0 : leftAmt06[0].toFixed(2);
                    leftAmt06[1] = sysConst.SALE_TARGET_2023.company06[1].value * 100000000 - saleAmt06[1] - winAmt06[1];
                    leftAmt06[1] = leftAmt06[1] < 0 ? 0 : leftAmt06[1].toFixed(2);
                    leftAmt06[2] = sysConst.SALE_TARGET_2023.company06[2].value * 100000000 - saleAmt06[2] - winAmt06[2];
                    leftAmt06[2] = leftAmt06[2] < 0 ? 0 : leftAmt06[2].toFixed(2);
                    leftAmt06[3] = sysConst.SALE_TARGET_2023.company06[3].value * 100000000 - saleAmt06[3] - winAmt06[3];
                    leftAmt06[3] = leftAmt06[3] < 0 ? 0 : leftAmt06[3].toFixed(2);
                    leftAmt07[0] = sysConst.SALE_TARGET_2023.company07[0].value * 100000000 - saleAmt07[0] - winAmt07[0];
                    leftAmt07[0] = leftAmt07[0] < 0 ? 0 : leftAmt07[0].toFixed(2);
                    leftAmt08[0] = sysConst.SALE_TARGET_2023.company08[0].value * 100000000 - saleAmt08[0] - winAmt08[0];
                    leftAmt08[0] = leftAmt08[0] < 0 ? 0 : leftAmt08[0].toFixed(2);

                    // 遍历 CRM 结果集
                    res.data.crm.forEach((currentValue) => {
                        switch (currentValue.orgNo) {
                            case "0102":
                                // 集团：商机，重点商机
                                nicheAmt[0] = nicheAmt[0] + parseFloat(currentValue.amt);
                                keyNicheAmt[0] = keyNicheAmt[0] + parseFloat(currentValue.keyPoint);
                                // 事业部：商机，重点商机
                                nicheAmt01[0] = parseFloat(currentValue.amt);
                                keyNicheAmt01[0] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0103":
                                nicheAmt[0] = nicheAmt[0] + parseFloat(currentValue.amt);
                                keyNicheAmt[0] = keyNicheAmt[0] + parseFloat(currentValue.keyPoint);

                                nicheAmt01[1] = parseFloat(currentValue.amt);
                                keyNicheAmt01[1] = parseFloat(currentValue.keyPoint);
                                break;
                            case "04":
                                nicheAmt[1] = nicheAmt[1] + parseFloat(currentValue.amt);
                                keyNicheAmt[1] = keyNicheAmt[1] + parseFloat(currentValue.keyPoint);

                                nicheAmt04[0] = parseFloat(currentValue.amt);
                                keyNicheAmt04[0] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0502":
                                nicheAmt[2] = nicheAmt[2] + parseFloat(currentValue.amt);
                                keyNicheAmt[2] = keyNicheAmt[2] + parseFloat(currentValue.keyPoint);

                                nicheAmt05[0] = parseFloat(currentValue.amt);
                                keyNicheAmt05[0] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0503":
                                nicheAmt[2] = nicheAmt[2] + parseFloat(currentValue.amt);
                                keyNicheAmt[2] = keyNicheAmt[2] + parseFloat(currentValue.keyPoint);

                                nicheAmt05[1] = parseFloat(currentValue.amt);
                                keyNicheAmt05[1] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0602":
                                nicheAmt[3] = nicheAmt[3] + parseFloat(currentValue.amt);
                                keyNicheAmt[3] = keyNicheAmt[3] + parseFloat(currentValue.keyPoint);

                                nicheAmt06[0] = parseFloat(currentValue.amt);
                                keyNicheAmt06[0] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0603":
                                nicheAmt[3] = nicheAmt[3] + parseFloat(currentValue.amt);
                                keyNicheAmt[3] = keyNicheAmt[3] + parseFloat(currentValue.keyPoint);

                                nicheAmt06[1] = parseFloat(currentValue.amt);
                                keyNicheAmt06[1] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0604":
                                nicheAmt[3] = nicheAmt[3] + parseFloat(currentValue.amt);
                                keyNicheAmt[3] = keyNicheAmt[3] + parseFloat(currentValue.keyPoint);

                                nicheAmt06[2] = parseFloat(currentValue.amt);
                                keyNicheAmt06[2] = parseFloat(currentValue.keyPoint);
                                break;
                            case "0605":
                                nicheAmt[3] = nicheAmt[3] + parseFloat(currentValue.amt);
                                keyNicheAmt[3] = keyNicheAmt[3] + parseFloat(currentValue.keyPoint);

                                nicheAmt06[3] = parseFloat(currentValue.amt);
                                keyNicheAmt06[3] = parseFloat(currentValue.keyPoint);
                                break;
                            case "07":
                                nicheAmt[4] = nicheAmt[4] + parseFloat(currentValue.amt);
                                keyNicheAmt[4] = keyNicheAmt[4] + parseFloat(currentValue.keyPoint);

                                nicheAmt07[0] = parseFloat(currentValue.amt);
                                keyNicheAmt07[0] = parseFloat(currentValue.keyPoint);
                                break;
                            case "08":
                                nicheAmt[5] = nicheAmt[5] + parseFloat(currentValue.amt);
                                keyNicheAmt[5] = keyNicheAmt[5] + parseFloat(currentValue.keyPoint);

                                nicheAmt08[0] = parseFloat(currentValue.amt);
                                keyNicheAmt08[0] = parseFloat(currentValue.keyPoint);
                                break;
                            default :
                                break;
                        }
                    });

                    // 集团总销售额
                    let totalSale = 0;
                    // 集团总中标未签单
                    let totalWin = 0;
                    // 集团总商机额
                    let totalNiche = 0;
                    // 按照法人循环 计算未完成额
                    for (let i = 0; i < leftAmt.length; i++) {
                        // 未完成额 = 计划任务 - 销售额
                        leftAmt[i] = sysConst.SALE_TARGET_2023.company[i].value * 100000000 - saleAmt[i] - winAmt[i];
                        // 小于0时，=0，大于0时，保留2位小数
                        leftAmt[i] = leftAmt[i] < 0 ? 0 : leftAmt[i].toFixed(2);
                        // 集团总销售额
                        totalSale = totalSale + parseFloat(saleAmt[i]);
                        // 集团总中标未签单
                        totalWin = totalWin + parseFloat(winAmt[i]);
                        // 集团总商机额
                        totalNiche = totalNiche + parseFloat(nicheAmt[i]);
                    }

                    // 00集团 销售完成情况
                    let optData = {
                        subtext : ((totalSale + totalWin)/100000000).toFixed(2) + '亿（完成率 ' + commonUtil.percentage(totalSale + totalWin, sysConst.SALE_TARGET_2023.compTotal * 100000000) + '%）',
                        yAxisData : companyNms,
                        seriesSale : saleAmt,
                        seriesWin : winAmt,
                        seriesLeft : leftAmt,
                        orgNum: '00'
                    };
                    // 集团 商机
                    let leftSale = sysConst.SALE_TARGET_2023.compTotal * 100000000 - totalSale - totalWin;
                    let nicheMul = leftSale <= 0 ? 0 : totalNiche / leftSale;
                    let optNicheData = {
                        subtext : (totalNiche/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul.toFixed(2) + '）',
                        subtextColor: nicheMul > 3 ? 'green' : (nicheMul < 1 ? 'red' : 'orange'),
                        yAxisData : companyNms,
                        seriesNiche : nicheAmt,
                        seriesKeyNiche : keyNicheAmt,
                        seriesLeft : leftAmt,
                        orgNum: '00'
                    };
                    // 设置eCharts图标数据
                    setCompanyInfo({
                        saleTarget: getSaleTargetOpt(saleTarget.compTotal, saleTarget.company),
                        saleInfo: getSaleInfoOpt(optData),
                        nicheInfo: getNicheInfoOpt(optNicheData)
                    });

                    // 01兆和空气 销售 + 中标额
                    let saleWin01 = parseFloat(saleAmt01[0]) + parseFloat(saleAmt01[1]) + parseFloat(winAmt01[0]) + parseFloat(winAmt01[1]);
                    let opt01Data = {
                        subtext : (saleWin01/100000000).toFixed(2) + '亿（完成率 ' + commonUtil.percentage(saleWin01, sysConst.SALE_TARGET_2023.comp01Total * 100000000) + '%）',
                        yAxisData : company01Nms,
                        seriesSale : saleAmt01,
                        seriesWin : winAmt01,
                        seriesLeft : leftAmt01
                    };
                    // 01兆和空气 未完成额
                    let leftSale01 = parseFloat(leftAmt01[0]) + parseFloat(leftAmt01[1]);
                    // 01兆和空气 商机
                    let niche01 = parseFloat(nicheAmt01[0]) + parseFloat(nicheAmt01[1]);
                    // 商机倍数
                    let nicheMul01 = leftSale01 <= 0 ? 0 : niche01 / leftSale01;
                    let opt01NicheData = {
                        subtext : (niche01/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul01.toFixed(2) + '）',
                        subtextColor: nicheMul01 > 3 ? 'green' : (nicheMul01 < 1 ? 'red' : 'orange'),
                        yAxisData : company01Nms,
                        seriesNiche : nicheAmt01,
                        seriesKeyNiche : keyNicheAmt01,
                        seriesLeft : leftAmt01
                    };
                    setComp01Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp01Total, saleTarget.company01),
                        saleInfo: getSaleInfoOpt(opt01Data),
                        nicheInfo: getNicheInfoOpt(opt01NicheData)
                    });

                    // 04天津兆和 销售 + 中标额
                    let saleWin04 = saleAmt04[0] + winAmt04[0];
                    let opt04Data = {
                        subtext : (saleWin04/100000000).toFixed(2) + '亿（完成率 ' + commonUtil.percentage(parseFloat(saleWin04), sysConst.SALE_TARGET_2023.comp04Total * 100000000) + '%）',
                        yAxisData : company04Nms,
                        seriesSale : saleAmt04,
                        seriesWin : winAmt04,
                        seriesLeft : leftAmt04
                    };
                    // 商机倍数
                    let nicheMul04 = parseFloat(leftAmt04[0]) <= 0 ? 0 : (parseFloat(nicheAmt04[0])) / (parseFloat(leftAmt04[0]));
                    let opt04NicheData = {
                        subtext : (nicheAmt04[0]/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul04.toFixed(2) + '）',
                        subtextColor: nicheMul04 > 3 ? 'green' : (nicheMul04 < 1 ? 'red' : 'orange'),
                        yAxisData : company04Nms,
                        seriesNiche : nicheAmt04,
                        seriesKeyNiche : keyNicheAmt04,
                        seriesLeft : leftAmt04
                    };
                    setComp04Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp04Total, saleTarget.company04),
                        saleInfo: getSaleInfoOpt(opt04Data),
                        nicheInfo: getNicheInfoOpt(opt04NicheData)
                    });

                    // 05苏州智能 销售 + 中标额
                    let saleWin05 = parseFloat(saleAmt05[0]) + parseFloat(saleAmt05[1]) + parseFloat(winAmt05[0]) + parseFloat(winAmt05[1]);
                    let opt05Data = {
                        subtext : (saleWin05 / 100000000).toFixed(2) + '亿（完成率 ' + commonUtil.percentage(saleWin05, sysConst.SALE_TARGET_2023.comp05Total * 100000000) + '%）',
                        yAxisData : company05Nms,
                        seriesSale : saleAmt05,
                        seriesWin : winAmt05,
                        seriesLeft : leftAmt05
                    };
                    // 未完成额
                    let leftSale05 = parseFloat(leftAmt05[0]) + parseFloat(leftAmt05[1]);
                    // 商机
                    let niche05 = parseFloat(nicheAmt05[0]) + parseFloat(nicheAmt05[1]);
                    // 商机倍数
                    let nicheMul05 = leftSale05 <= 0 ? 0 : niche05 / leftSale05;
                    let opt05NicheData = {
                        subtext : (niche05 / 100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul05.toFixed(2) + '）',
                        subtextColor: nicheMul05 > 3 ? 'green' : (nicheMul05 < 1 ? 'red' : 'orange'),
                        yAxisData : company05Nms,
                        seriesNiche : nicheAmt05,
                        seriesKeyNiche : keyNicheAmt05,
                        seriesLeft : leftAmt05
                    };
                    setComp05Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp05Total, saleTarget.company05),
                        saleInfo: getSaleInfoOpt(opt05Data),
                        nicheInfo: getNicheInfoOpt(opt05NicheData)
                    });

                    // 06苏州环能
                    let saleWin06 = parseFloat(saleAmt06[0]) + parseFloat(saleAmt06[1]) + parseFloat(saleAmt06[2]) + parseFloat(saleAmt06[3])
                        + parseFloat(winAmt06[0]) + parseFloat(winAmt06[1]) + parseFloat(winAmt06[2]) + parseFloat(winAmt06[3]);
                    let opt06Data = {
                        subtext : (saleWin06 / 100000000).toFixed(2) + '亿（完成率 ' + commonUtil.percentage(saleWin06, sysConst.SALE_TARGET_2023.comp06Total * 100000000) + '%）',
                        yAxisData : company06Nms,
                        seriesSale : saleAmt06,
                        seriesWin : winAmt06,
                        seriesLeft : leftAmt06
                    };
                    // 未完成额
                    let leftSale06 = parseFloat(leftAmt06[0]) + parseFloat(leftAmt06[1]) + parseFloat(leftAmt06[2]) + parseFloat(leftAmt06[3]);
                    // 商机
                    let niche06 = parseFloat(nicheAmt06[0]) + parseFloat(nicheAmt06[1]) + parseFloat(nicheAmt06[2]) + parseFloat(nicheAmt06[3]);
                    // 商机倍数
                    let nicheMul06 = leftSale06 <= 0 ? 0 : niche06 / leftSale06;
                    let opt06NicheData = {
                        subtext : (niche06/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul06.toFixed(2) + '）',
                        subtextColor: nicheMul06 > 3 ? 'green' : (nicheMul06 < 1 ? 'red' : 'orange'),
                        yAxisData : company06Nms,
                        seriesNiche : nicheAmt06,
                        seriesKeyNiche : keyNicheAmt06,
                        seriesLeft : leftAmt06
                    };
                    setComp06Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp06Total, saleTarget.company06),
                        saleInfo: getSaleInfoOpt(opt06Data),
                        nicheInfo: getNicheInfoOpt(opt06NicheData)
                    });

                    // 07上海兆和
                    let saleWin07 = parseFloat(saleAmt07[0]) + parseFloat(winAmt07[0]);
                    let opt07Data = {
                        subtext : (saleWin07/100000000).toFixed(2) + '亿（完成率 '  + commonUtil.percentage(saleWin07 , sysConst.SALE_TARGET_2023.comp07Total * 100000000) + '%）',
                        yAxisData : company07Nms,
                        seriesSale : saleAmt07,
                        seriesWin : winAmt07,
                        seriesLeft : leftAmt07
                    };

                    // 商机倍数
                    let nicheMul07 = parseFloat(leftAmt07[0]) <= 0 ? 0 : (parseFloat(nicheAmt07[0])) / (parseFloat(leftAmt07[0]));
                    let opt07NicheData = {
                        subtext : (nicheAmt07[0]/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul07.toFixed(2) + '）',
                        subtextColor: nicheMul07 > 3 ? 'green' : (nicheMul07 < 1 ? 'red' : 'orange'),
                        yAxisData : company07Nms,
                        seriesNiche : nicheAmt07,
                        seriesKeyNiche : keyNicheAmt07,
                        seriesLeft : leftAmt07
                    };
                    setComp07Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp07Total, saleTarget.company07),
                        saleInfo: getSaleInfoOpt(opt07Data),
                        nicheInfo: getNicheInfoOpt(opt07NicheData)
                    });

                    // 08兆和数字
                    let saleWin08 = parseFloat(saleAmt08[0]) + parseFloat(winAmt08[0]);
                    let percent = 0;
                    if (sysConst.SALE_TARGET_2023.comp08Total === 0) {
                        percent = 100;
                    } else {
                        percent = commonUtil.percentage(saleWin08 , sysConst.SALE_TARGET_2023.comp08Total * 100000000);
                    }
                    let opt08Data = {
                        subtext : (saleWin08/10000).toFixed(2) + '万（完成率 '  + percent + '%）',
                        // subtext : (saleWin08/100000000).toFixed(2) + '亿（完成率 '  + percent + '%）',
                        yAxisData : company08Nms,
                        seriesSale : saleAmt08,
                        seriesWin : winAmt08,
                        seriesLeft : leftAmt08,
                        orgNum: '08'
                    };
                    let nicheMul08 = parseFloat(leftAmt08[0]) <= 0 ? 0 : (parseFloat(nicheAmt08[0])) / (parseFloat(leftAmt08[0]));
                    let opt08NicheData = {
                        subtext : (nicheAmt08[0]/10000).toFixed(2) + '万（商机倍数 ' + nicheMul08.toFixed(2) + '）',
                        // subtext : (nicheAmt08[0]/100000000).toFixed(2) + '亿（商机倍数 ' + nicheMul08.toFixed(2) + '）',
                        subtextColor: nicheMul08 > 3 ? 'green' : (nicheMul08 < 1 ? 'red' : 'orange'),
                        yAxisData : company08Nms,
                        seriesNiche : nicheAmt08,
                        seriesKeyNiche : keyNicheAmt08,
                        seriesLeft : leftAmt08,
                        orgNum: '08'
                    };
                    setComp08Info({
                        saleTarget: getSaleTargetOpt(saleTarget.comp08Total, saleTarget.company08),
                        saleInfo: getSaleInfoOpt(opt08Data),
                        nicheInfo: getNicheInfoOpt(opt08NicheData)
                    });
                } else {
                    if (!res.data.success) {
                        // res.data.message=会话信息已丢失，请重新登录
                        // appAction.loginKd();
                        alert("调用金蝶API异常，请尝试刷新页面！");
                    } else {
                        alert(`getSaleInfoStat no data`)
                    }
                }
                setLoading(false);
            }).catch(error => {
            alert('getSaleInfoStat error');
            console.log("getSaleInfoStat err, " + JSON.stringify(error));
            setLoading(false);
        })
    };

    const changeTab = (key) => {};

    const onChartClick = (e) => {
        // console.log("onChartClick");
        // setOptionData(option2);
    };

    const onChartLegendselectchanged = (e) => {
        // console.log("onChartLegendselectchanged");
        // console.log(e);
    };

    const onEvents = {
        'click': onChartClick,
        'legendselectchanged': onChartLegendselectchanged
    };

    const disabledDate = (current) => {
        // 2023年之前 和 未来月份不能选额
        return current < dayjs('2023-01-01') || dayjs().add(0,'year') < current;
    };

    const changeYear = (date, dateString) => {
        if (dateString !== '2023') {
            alert("暂无选择年份的目标，请联系相关责任人确认！");
            return;
        }
        if (date != null) {
            setChooseYear(date);
            getSaleInfoStat(dateString);
        }
    };

    return (
        <>
            {/* 加载中动画，居中显示 */}
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '46%'}}>
                <Spin size="large" spinning={loading}/>
            </div>

            {/*{roleFlag === -1 &&*/}
            {/*<div style={{zIndex: 999, position: 'absolute', top: '40%', left: '35%'}}>*/}
            {/*    <span>查询钉钉权限中...</span>*/}
            {/*</div>}*/}

            {roleFlag === 0 &&
            <div style={{zIndex: 999, position: 'absolute', top: '40%', left: '12%'}}>
                <span>您没有查看此内容权限，请与管理人员联系！</span>
            </div>}


            <div>
                &nbsp;年份：<DatePicker picker="year" bordered={false} disabledDate={disabledDate}
                            value={chooseYear}
                            onChange={(date,dateString) => {changeYear(date,dateString)}}/>
            </div>

            {roleFlag === 1 &&
            <Tabs
                defaultActiveKey="1"
                onChange={changeTab}
                tabBarStyle={{paddingLeft:5, position: 'sticky', top: 0, zIndex : 999, backgroundColor: 'white'}}
                items={[
                    {
                        key: '1', label: `兆和集团`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={companyInfo.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={companyInfo.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={companyInfo.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '2', label: `兆和空气`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp01Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp01Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp01Info.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '3', label: `天津兆和`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp04Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp04Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp04Info.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '4', label: `苏州智能`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp05Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp05Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp05Info.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '5', label: `苏州环能`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp06Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp06Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp06Info.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '6', label: `上海兆和`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp07Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp07Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp07Info.nicheInfo}/>
                            </>
                        )
                    }
                    , {
                        key: '7', label: `兆和数字`,
                        children: (
                            <>
                                <ReactECharts style={{height: 320}} option={comp08Info.saleTarget}/>
                                <ReactECharts style={{marginTop: 0}} option={comp08Info.saleInfo}/>
                                <ReactECharts style={{marginTop: 15}} option={comp08Info.nicheInfo}/>
                            </>
                        )
                    }
                ]}
            />}

            {/*/!* 图表 *!/*/}
            {/*<ReactECharts style={{marginTop: 115}} option={optionData} onEvents={{*/}
            {/*    'click': onChartClick,*/}
            {/*    'legendselectchanged': onChartLegendselectchanged*/}
            {/*}}/>*/}
        </>
    );
};

export default CrmStat;