/**
 * 组装sheet【营收及成本】数据源
 * @param mData 页面数据
 * @returns sheet【营收及成本】数据源
 */
export const getSheetSaleOrder = (mData) => {
    return {
        sheetName: '营收及成本',
        sheetData: [
            {one: '订单额', two: mData.saleOrder.main},
            {one: '主营收入', two: mData.saleOutOrder.main},
            {one: '', two: mData.saleOutOrder.left},
            {one: '领料成本金额', two: mData.pickCost.main},
            {one: '', two: mData.pickCost.left},
            {one: '', two: mData.pickCost.right},
            {one: '物流费用', two: mData.logiCost.main},
            {one: '', two: mData.logiCost.left},
            {one: '外协费用', two: mData.outSrc.main},
            {one: '', two: mData.outSrc.left},
            {one: '包装箱费', two: mData.packBoxFee.main},
            {one: '', two: mData.packBoxFee.left}
        ],
        columnWidths: [6, 12]
    };
};

/**
 * 组装sheet【生产运营效率】数据源
 * @param mData 页面数据
 * @returns sheet【生产运营效率】数据源
 */
export const getSheetSalOutStock = (mData) => {
    // 班组任务完成及时率
    let teamList = [];
    // 遍历班组数据
    mData.groupTask.list.map(function (item, index) {
        teamList.push({one: '', two: item.team_name + '：' + item.rate + '% (' + item.numerator + '/' + item.denominator + ')'});
    });
    // 有效工时占比
    let hourList = [];
    // 遍历工时数据
    mData.workHour.list.map(function (item, index) {
        hourList.push({one: '', two: item.team_name + '：' + item.work_rate + '% (' + item.work_time + '/' + item.ding_hour + ')'});
    });

    return {
        sheetName: '生产运营效率',
        sheetData: [
            {one: '产品发货及时率', two: mData.salOutStock.main},
            {one: '', two: mData.salOutStock.left},
            {one: '', two: mData.salOutStock.right},
            {one: '采购交付及时率', two: mData.purOrder.main},
            {one: '', two: mData.purOrder.left},
            {one: '', two: mData.purOrder.right},
            {one: '生产订单完成及时率', two: mData.prdMo.main},
            {one: '', two: mData.prdMo.left},
            {one: '', two: mData.prdMo.right},
            {one: '生产计划达成率', two: mData.prdInStock.main},
            {one: '', two: mData.prdInStock.left},
            {one: '', two: mData.prdInStock.right},
            {one: '制造外协完成及时率', two: mData.manuOutTim.main},
            {one: '', two: mData.manuOutTim.left},
            {one: '', two: mData.manuOutTim.right},
            {one: '班组任务完成及时率', two: mData.groupTask.main},
            ...teamList,
            {one: '有效工时占比', two: mData.workHour.main},
            ...hourList
        ],
        columnWidths: [9, 12]
    };
};

/**
 * 组装sheet【技术BOM】数据源
 * @param mData 页面数据
 * @returns sheet【技术BOM】数据源
 */
export const getSheetPrdBom = (mData) => {
    return {
        sheetName: '技术BOM',
        sheetData: [
            {one: 'BOM准确率_环保机电', two: mData.prdBom0102.main},
            {one: '', two: mData.prdBom0102.left},
            {one: '', two: mData.prdBom0102.right},
            {one: '', two: mData.prdBom0102.third},
            {one: 'BOM准确率_有机废气', two: mData.prdBom0103.main},
            {one: '', two: mData.prdBom0103.left},
            {one: '', two: mData.prdBom0103.right},
            {one: '', two: mData.prdBom0103.third},
            {one: 'BOM准确率_大连除尘', two: mData.prdBom0105.main},
            {one: '', two: mData.prdBom0105.left},
            {one: '', two: mData.prdBom0105.right},
            {one: '', two: mData.prdBom0105.third},
            {one: 'BOM准确率_兆和数字', two: mData.prdBom08.main},
            {one: '', two: mData.prdBom08.left},
            {one: '', two: mData.prdBom08.right},
            {one: '', two: mData.prdBom08.third}
        ],
        columnWidths: [9, 12]
    };
};

/**
 * 组装sheet【质量&损耗】数据源
 * @param mData 页面数据
 * @returns sheet【质量&损耗】数据源
 */
export const getSheetZdpPrdMo = (mData) => {
    return {
        sheetName: '质量&损耗',
        sheetData: [
            {one: '成品检验合格率', two: mData.zdpPrdMo.main},
            {one: '', two: mData.zdpPrdMo.left},
            {one: '', two: mData.zdpPrdMo.right},
            {one: '生产过程检验合格率', two: mData.prdMoProc.main},
            {one: '', two: mData.prdMoProc.left},
            {one: '', two: mData.prdMoProc.right},
            {one: '采购品验收合格率', two: mData.purReceive.main},
            {one: '', two: mData.purReceive.left},
            {one: '', two: mData.purReceive.right},
            {one: '制造外协合格率', two: mData.manuOutSuc.main},
            {one: '', two: mData.manuOutSuc.left},
            {one: '', two: mData.manuOutSuc.right},
            {one: '产品质量损失额', two: mData.zdpPrdMoLos.left},
            {one: '', two: mData.zdpPrdMoLos.right}
        ],
        columnWidths: [9, 12]
    };
};